import React from 'react';

import './styles.scss';
import {IJournalStatisticValue} from '../../../redux/journal-stats/interfaces';

interface IJournalStatValues {
  values: IJournalStatisticValue[]
  name: string
}

export const JournalStatSymptomValues: React.FunctionComponent<IJournalStatValues> = ({values, name}: IJournalStatValues) => {
  return <div className="stats__values-group-symptoms">
    <div className="stats__values-group-name">{name}</div>
    {values.map((v) => {
      return <div key={v.xscCode} className="stats__value">
        <div className="stats__value-text">
          <div className="stats__value-text-question">{v.question}</div>
          <div className="stats__value-text-answer">{v.answer}</div>
        </div>
        <div className="stats__value-number">
          {v.value}
        </div>
      </div>;
    })}
  </div>;
};
